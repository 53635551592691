<template>
  <v-container id="dashboard" fluid tag="section">
    <v-row>
      <v-col cols="12" md="12">
        <!-- สำนักงานอาชีวศึกษาจังหวัด สมัครผู้อำนวยการวิทยาลัย -->
        <v-hover v-slot="{ hover }">
          <v-card
            :elevation="hover ? 16 : 2"
            :class="{ 'on-hover': hover }"
            class="pa-2"
            v-if="assessment_directiors.period_vecprovince_enable === '1'"
          >
            <v-row cols="12" md="7">
              <v-col>
                <v-alert
                  outlined
                  prominent
                  icon="mdi-bell-ring"
                  type="error"
                  border="left"
                >
                  <h2 class="secondary--text">
                    <v-icon color="secondary">mdi-domain</v-icon>
                    สำนักงานอาชีวศึกษาจังหวัด
                  </h2>

                  <h3>
                    ดำเนินการ <u> ประเมิน ภาค ข ส่วนที่ 1 ผู้สมัครคัดเลือก</u>
                    <br />
                    <u>ตำแหน่ง ผู้อำนวยการวิทยาลัย </u>
                    <br />
                    ครั้งที่
                    {{ assessment_directiors.period_vecprovince_times }} /
                    {{ assessment_directiors.period_vecprovince_year }}

                    ก่อนระบบปิดในวันที่
                    {{
                      assessment_directiors.period_vecprovince_stop
                        | moment("add", "543 years")
                        | moment("D MMMM YYYY")
                    }}
                  </h3>
                  <v-btn color="info" to="/vecprovince/director_app_b">
                    <v-icon class="pr-2">mdi-account-convert</v-icon>
                    ผู้สมัคร</v-btn
                  >
                </v-alert>
              </v-col>
              <v-col cols="12" md="6">
                <div class="text-center">
                  <h3>
                    ปิดระบบ ตรวจสอบเอกสาร ผู้สมัครสอบ : <br />
                    {{
                      assessment_directiors.period_vecprovince_stop
                        | moment("add", "543 years")
                        | moment("D MMMM YYYY")
                    }}
                    <br />
                    เหลือเวลาอีก
                  </h3>
                  <flip-countdown
                    :deadline="
                      assessment_directiors.period_vecprovince_stop +
                        ' 24:00:00'
                    "
                    :labels="labels"
                  ></flip-countdown>
                </div>
              </v-col>
            </v-row>
          </v-card>
        </v-hover>

        <!-- สำนักงานอาชีวศึกษาจังหวัด สมัครรองผู้อำนวยการวิทยาลัย -->
        <v-hover v-slot="{ hover }">
          <v-card
            :elevation="hover ? 16 : 2"
            :class="{ 'on-hover': hover }"
            class="pa-2"
            v-if="verify_sedirectiors.period_vecprovince_enable === '1'"
          >
            <v-row cols="12" md="7">
              <v-col>
                <v-alert
                  outlined
                  prominent
                  icon="mdi-bell-ring"
                  type="error"
                  border="left"
                >
                  <h2 class="secondary--text">
                    <v-icon color="secondary">mdi-domain</v-icon>
                    สำนักงานอาชีวศึกษาจังหวัด
                  </h2>

                  <h3>
                    ดำเนินการ <u> ตรวจสอบเอกสาร ผู้สมัครสอบ</u>
                    <br />
                    <u>ตำแหน่ง รองผู้อำนวยการวิทยาลัย </u>
                    <br />
                    ครั้งที่
                    {{ verify_sedirectiors.period_vecprovince_times }} /
                    {{ verify_sedirectiors.period_vecprovince_year }}

                    ก่อนระบบปิดในวันที่
                    {{
                      verify_sedirectiors.period_vecprovince_stop
                        | moment("add", "543 years")
                        | moment("D MMMM YYYY")
                    }}
                  </h3>
                  <v-btn color="info" to="/vecprovince/sedirector_app">
                    <v-icon class="pr-2">mdi-account-convert</v-icon>
                    ผู้สมัครสอบ</v-btn
                  >
                </v-alert>
              </v-col>
              <v-col cols="12" md="6">
                <div class="text-center">
                  <h3>
                    ปิดระบบ ตรวจสอบเอกสาร ผู้สมัครสอบ : <br />
                    {{
                      verify_sedirectiors.period_vecprovince_stop
                        | moment("add", "543 years")
                        | moment("D MMMM YYYY")
                    }}
                    <br />
                    เหลือเวลาอีก
                  </h3>
                  <flip-countdown
                    :deadline="
                      verify_sedirectiors.period_vecprovince_stop + ' 24:00:00'
                    "
                    :labels="labels"
                  ></flip-countdown>
                </div>
              </v-col>
            </v-row>
          </v-card>
        </v-hover>

        <!-- สำนักงานอาชีวศึกษาจังหวัด สมัคร ผู้อำนวยการวิทยาลัย -->
        <v-hover v-slot="{ hover }">
          <v-card
            :elevation="hover ? 16 : 2"
            :class="{ 'on-hover': hover }"
            class="pa-2"
            v-if="verify_directiors.period_vecprovince_enable === '1'"
          >
            <v-row cols="12" md="7">
              <v-col>
                <v-alert
                  outlined
                  prominent
                  icon="mdi-bell-ring"
                  type="error"
                  border="left"
                >
                  <h2 class="secondary--text">
                    <v-icon color="secondary">mdi-domain</v-icon>
                    สำนักงานอาชีวศึกษาจังหวัด
                  </h2>

                  <h3>
                    ดำเนินการ <u> ตรวจสอบเอกสาร ผู้สมัครสอบ</u>
                    <br />
                    <u>ตำแหน่ง ผู้อำนวยการวิทยาลัย </u>
                    <br />
                    ครั้งที่
                    {{ verify_directiors.period_vecprovince_times }} /
                    {{ verify_directiors.period_vecprovince_year }}

                    ก่อนระบบปิดในวันที่
                    {{
                      verify_directiors.period_vecprovince_stop
                        | moment("add", "543 years")
                        | moment("D MMMM YYYY")
                    }}
                  </h3>
                  <v-btn color="info" to="/vecprovince/director_app">
                    <v-icon class="pr-2">mdi-account-convert</v-icon>
                    ผู้สมัครสอบ</v-btn
                  >
                </v-alert>
              </v-col>
              <v-col cols="12" md="6">
                <div class="text-center">
                  <h3>
                    ปิดระบบ ตรวจสอบเอกสาร ผู้สมัครสอบ : <br />
                    {{
                      verify_directiors.period_vecprovince_stop
                        | moment("add", "543 years")
                        | moment("D MMMM YYYY")
                    }}
                    <br />
                    เหลือเวลาอีก
                  </h3>
                  <flip-countdown
                    :deadline="
                      verify_directiors.period_vecprovince_stop + ' 24:00:00'
                    "
                    :labels="labels"
                  ></flip-countdown>
                </div>
              </v-col>
            </v-row>
          </v-card>
        </v-hover>

        <!-- สำนักงานอาชีวศึกษาจังหวัด ประเมินรองผู้อำนวยการวิทยาลัย ภาค ก -->
        <v-hover v-slot="{ hover }">
          <v-card
            :elevation="hover ? 16 : 2"
            :class="{ 'on-hover': hover }"
            class="pa-2"
            v-if="assess_sedirectiors.period_vecprovince_enable === '1'"
          >
            <v-row cols="12" md="7">
              <v-col>
                <v-alert
                  outlined
                  prominent
                  icon="mdi-bell-ring"
                  type="error"
                  border="left"
                >
                  <h2 class="secondary--text">
                    <v-icon color="secondary">mdi-domain</v-icon>
                    สำนักงานอาชีวศึกษาจังหวัด
                  </h2>

                  <h3>
                    ดำเนินการ
                    <u> รายงานผลการประเมิน ภาค ก จากต้นสังกัดสถานศึกษา</u>
                    <br />
                    <u>ตำแหน่ง รองผู้อำนวยการวิทยาลัย </u>
                    <br />
                    ครั้งที่
                    {{ assess_sedirectiors.period_vecprovince_times }} /
                    {{ assess_sedirectiors.period_vecprovince_year }}

                    ก่อนระบบปิดในวันที่
                    {{
                      assess_sedirectiors.period_vecprovince_stop
                        | moment("add", "543 years")
                        | moment("D MMMM YYYY")
                    }}
                  </h3>
                  <v-btn color="info" to="/vecprovince/sedirector_app_a">
                    <v-icon class="pr-2">mdi-account-convert</v-icon> ประเมิน
                    ผู้สมัครสอบ</v-btn
                  >
                </v-alert>
              </v-col>
              <v-col cols="12" md="6">
                <div class="text-center">
                  <h3>
                    ปิดระบบ ตรวจสอบเอกสาร ผู้สมัครสอบ : <br />
                    {{
                      assess_sedirectiors.period_vecprovince_stop
                        | moment("add", "543 years")
                        | moment("D MMMM YYYY")
                    }}
                    <br />
                    เหลือเวลาอีก
                  </h3>
                  <flip-countdown
                    :deadline="
                      assess_sedirectiors.period_vecprovince_stop + ' 24:00:00'
                    "
                    :labels="labels"
                  ></flip-countdown>
                </div>
              </v-col>
            </v-row>
          </v-card>
        </v-hover>

        <!-- สำนักงานอาชีวศึกษาจังหวัด ประเมินรองผู้อำนวยการวิทยาลัย ภาค ข -->
        <v-hover v-slot="{ hover }">
          <v-card
            :elevation="hover ? 16 : 2"
            :class="{ 'on-hover': hover }"
            class="pa-2"
            v-if="assess_sedirectiors_B.period_vecprovince_enable === '1'"
          >
            <v-row cols="12" md="7">
              <v-col>
                <v-alert
                  outlined
                  prominent
                  icon="mdi-bell-ring"
                  type="error"
                  border="left"
                >
                  <h2 class="secondary--text">
                    <v-icon color="secondary">mdi-domain</v-icon>
                    สำนักงานอาชีวศึกษาจังหวัด
                  </h2>

                  <h3>
                    ดำเนินการ
                    <u> รายงานผลการประเมิน ภาค ข จากอาชีวศึกษาจังหวัด</u>
                    <br />
                    <u>ตำแหน่ง รองผู้อำนวยการวิทยาลัย </u>
                    <br />
                    ครั้งที่
                    {{ assess_sedirectiors_B.period_vecprovince_times }} /
                    {{ assess_sedirectiors_B.period_vecprovince_year }}

                    ก่อนระบบปิดในวันที่
                    {{
                      assess_sedirectiors_B.period_vecprovince_stop
                        | moment("add", "543 years")
                        | moment("D MMMM YYYY")
                    }}
                  </h3>
                  <v-btn color="info" to="/vecprovince/sedirector_app_b">
                    <v-icon class="pr-2">mdi-account-convert</v-icon> ประเมิน
                    ผู้สมัครสอบ</v-btn
                  >
                </v-alert>
              </v-col>
              <v-col cols="12" md="6">
                <div class="text-center">
                  <h3>
                    ปิดระบบ ตรวจสอบเอกสาร ผู้สมัครสอบ : <br />
                    {{
                      assess_sedirectiors_B.period_vecprovince_stop
                        | moment("add", "543 years")
                        | moment("D MMMM YYYY")
                    }}
                    <br />
                    เหลือเวลาอีก
                  </h3>
                  <flip-countdown
                    :deadline="
                      assess_sedirectiors_B.period_vecprovince_stop +
                        ' 24:00:00'
                    "
                    :labels="labels"
                  ></flip-countdown>
                </div>
              </v-col>
            </v-row>
          </v-card>
        </v-hover>

        <!-- สำนักงานอาชีวศึกษาจังหวัด ครู -->
        <v-hover v-slot="{ hover }">
          <v-card
            :elevation="hover ? 16 : 2"
            :class="{ 'on-hover': hover }"
            class="pa-2"
            v-if="period_vecprovinceTeacher.period_vecprovince_enable === '1'"
          >
            <v-row cols="12" md="7">
              <v-col>
                <v-alert
                  outlined
                  prominent
                  icon="mdi-bell-ring"
                  type="error"
                  border="left"
                >
                  <h2 class="secondary--text">
                    <v-icon color="secondary">mdi-domain</v-icon>
                    สำนักงานอาชีวศึกษาจังหวัด
                  </h2>

                  <h3>
                    ดำเนินการ
                    <u>
                      เสนอรายชื่อคณะกรรมการ/พิจารณาตรวจสอบการย้าย ตำแแหน่งครู
                    </u>
                    <br />
                    ครั้งที่
                    {{ period_vecprovinceTeacher.period_vecprovince_times }} /
                    {{ period_vecprovinceTeacher.period_vecprovince_year }}
                    ก่อนระบบปิดในวันที่
                    {{
                      period_vecprovinceTeacher.period_vecprovince_stop
                        | moment("add", "543 years")
                        | moment("D MMMM YYYY")
                    }}
                  </h3>
                  <v-btn color="info" to="/vecprovince/commiteevecpro">
                    <v-icon class="pr-2">mdi-account-multiple-plus</v-icon>
                    แต่งตั้งคณะกรรมการตรวจสอบ</v-btn
                  >
                  <v-btn color="info" to="/vecprovince/transference_personnel">
                    <v-icon class="pr-2">mdi-account-convert</v-icon>
                    ผู้เสนอย้าย</v-btn
                  >
                </v-alert>
              </v-col>
              <v-col cols="12" md="6">
                <div class="text-center">
                  <h3>
                    ปิดระบบ การพิจารณาตรวจสอบการย้าย ตำแแหน่งครู : <br />
                    {{
                      period_vecprovinceTeacher.period_vecprovince_stop
                        | moment("add", "543 years")
                        | moment("D MMMM YYYY")
                    }}
                    <br />
                    เหลือเวลาอีก
                  </h3>
                  <flip-countdown
                    :deadline="
                      period_vecprovinceTeacher.period_vecprovince_stop +
                        ' 24:00:00'
                    "
                    :labels="labels"
                  ></flip-countdown>
                </div>
              </v-col>
            </v-row>
          </v-card>
        </v-hover>
        <!-- สำนักงานอาชีวศึกษาจังหวัด ผู้บริหาร-->
        <v-hover v-slot="{ hover }">
          <v-card
            :elevation="hover ? 16 : 2"
            :class="{ 'on-hover': hover }"
            class="pa-2"
            v-if="period_vecprovincemanage.period_vecprovince_enable === '1'"
          >
            <v-row cols="12" md="7">
              <v-col>
                <v-alert
                  outlined
                  prominent
                  icon="mdi-bell-ring"
                  type="error"
                  border="left"
                >
                  <h2 class="primary--text">สำนักงานอาชีวศึกษาจังหวัด</h2>

                  <h3>
                    ดำเนินการ
                    <u>
                      เสนอรายชื่อคณะกรรมการ/พิจารณาตรวจสอบการย้าย ตำแแหน่งครู
                    </u>
                    <br />
                    ครั้งที่
                    {{ period_vecprovincemanage.period_vecprovince_times }} /
                    {{ period_vecprovincemanage.period_vecprovince_year }}
                    ก่อนระบบปิดในวันที่
                    {{
                      period_vecprovincemanage.period_vecprovince_stop
                        | moment("add", "543 years")
                        | moment("D MMMM YYYY")
                    }}
                  </h3>
                  <v-btn color="info" to="/vecprovince/commiteevecpro">
                    <v-icon class="pr-2">mdi-account-multiple-plus</v-icon>
                    แต่งตั้งคณะกรรมการตรวจสอบ</v-btn
                  >
                  <v-btn color="info" to="/vecprovince/transference_personnel">
                    <v-icon class="pr-2">mdi-account-convert</v-icon>
                    ผู้เสนอย้าย</v-btn
                  >
                </v-alert>
              </v-col>
              <v-col cols="12" md="6">
                <div class="text-center">
                  <h3>
                    ปิดระบบ การพิจารณาตรวจสอบการย้าย ตำแแหน่งครู : <br />
                    {{
                      period_vecprovincemanage.period_vecprovince_stop
                        | moment("add", "543 years")
                        | moment("D MMMM YYYY")
                    }}
                    <br />
                    เหลือเวลาอีก
                  </h3>
                  <flip-countdown
                    :deadline="
                      period_vecprovincemanage.period_vecprovince_stop +
                        ' 24:00:00'
                    "
                    :labels="labels"
                  ></flip-countdown>
                </div>
              </v-col>
            </v-row>
          </v-card>
        </v-hover>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="12">
        <base-material-card color="success" class="px-5 py-3">
          <template v-slot:heading>
            <div class="font-weight-light">
              ข้าราชการครูและบุคลากรทางการศึกษา
            </div>
          </template>
          <v-card-text>
            <v-data-table :headers="headers" :items="showAlldata">
              <template v-slot:top>
                <v-text-field
                  v-model="search_branch"
                  label="ค้นหา :"
                  class="mx-4"
                ></v-text-field>
              </template>
            </v-data-table>
          </v-card-text>
        </base-material-card>
      </v-col>
      <v-col cols="12">
        <Vecprovincepersonnel_temrang />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import FlipCountdown from "vue2-flip-countdown";
import Vecprovincepersonnel_temrang from "../../component/vecprovince/Vecprovincepersonnel_temrang";
export default {
  name: "DashboardDashboard",
  components: { FlipCountdown, Vecprovincepersonnel_temrang },
  data() {
    return {
      headers: [
        { text: "วิทยาลัย", align: "left", value: "college_name" },
        { text: "จำนวนทั้งหมด", align: "left", value: "count_all" },
        { text: "จำนวนผู้อำนวยการ", align: "left", value: "count_dr" },
        { text: "จำนวนรองผู้อำนวยการ", align: "left", value: "count_se_dr" },
        { text: "จำนวนครู", align: "left", value: "count_tech" },
        { text: "จำนวนครูผู้ช่วย", align: "left", value: "count_perpare" }
      ],

      search: "",
      search_branch: "",
      pagination: {},
      ApiKey: "HRvec2021",

      rowsperpage: [
        25,
        50,
        100,
        {
          text: "All",
          value: -1
        }
      ],

      user: [],
      showAlldata_rang: [],
      colleges: [],
      provinces: [],
      prefectures: [],
      regions: [],
      region_ena: true,
      showAlldata: [],
      showcollegedata: {},
      showbranchdata: [],
      period_colleges: [],
      period_college_moves: [],
      rate_work_g_all: [],
      conditions_transfers: [],
      loading: true,
      dialogLoading: true,
      interval: {},
      value: 0,
      perioddiscuss: [],
      periods: [],
      deadline: [],
      verify_sedirectiors: [],
      assessment_directiors: [],
      verify_directiors: [],
      stop: {
        type: Boolean
      },
      showDays: {
        type: Boolean,
        required: false,
        default: true
      },
      showHours: {
        type: Boolean,
        required: false,
        default: true
      },
      showMinutes: {
        type: Boolean,
        required: false,
        default: true
      },
      showSeconds: {
        type: Boolean,
        required: false,
        default: true
      },
      labels: {
        days: "วัน",
        hours: "ชั่วโมง",
        minutes: "นาที",
        seconds: "วินาที"
      },
      countdownSize: {
        type: String,
        required: false
      },
      labelSize: {
        type: String,
        required: false
      },
      period_vecprovinceTeacher: [],
      period_vecprovincemanage: [],
      assess_sedirectiors: [],
      assess_sedirectiors_B: []
    };
  },
  async mounted() {
    await this.sweetAlertLoading()
    await this.getuser();
    await this.getAlldata();
    await this.period_vecprovinceTeacherQuery(); /* พิจารณาย้ายสายการสอน */
    await this.period_vecprovincemanageQuery(); /* พิจารณาย้ายสายบริหาร */
    await this.period_sedirectorQuery(); /* พิจารณาย้ายสายบริหาร */
    await this.period_directorQuery(); /* พิจารณาย้ายสายบริหาร */
    await this.period_sedirectorAssessQuery(); /* พิจารณาย้ายสายบริหาร ก*/
    await this.period_sedirectorAssessBQuery(); /* พิจารณาย้ายสายบริหาร ข*/

    await this.period_directorAssQuery(); /* พิจารณาย้ายสายบริหาร ผอ ข*/
    Swal.close()
  },

  methods: {
    async sweetAlertLoading() {
      Swal.fire({
        title: "Please Wait !",
        timerProgressBar: true,
        didOpen: () => {
          Swal.showLoading();
        },
        onBeforeOpen: () => {
          Swal.showLoading();
        },
        onAfterClose: () => {
          Swal.hideLoading();
        },
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false
      });
    },

    async period_sedirectorQuery() {
      let result_period;
      result_period = await this.$http.post("period_vecprovince.php", {
        ApiKey: this.ApiKey,
        period_vecprovinc_id: "201",
        period_vecprovince_enable: "1"
      });
      this.verify_sedirectiors = result_period.data;
    },

    async period_directorQuery() {
      let result_period;
      result_period = await this.$http.post("period_vecprovince.php", {
        ApiKey: this.ApiKey,
        period_vecprovinc_id: "401",
        period_vecprovince_enable: "1"
      });
      this.verify_directiors = result_period.data;
    },

    async period_directorAssQuery() {
      let result_period;
      result_period = await this.$http.post("period_vecprovince.php", {
        ApiKey: this.ApiKey,
        period_vecprovinc_id: "40102"
      });
      this.assessment_directiors = result_period.data;
    },

    async period_sedirectorAssessQuery() {
      let result_period;
      result_period = await this.$http.post("period_vecprovince.php", {
        ApiKey: this.ApiKey,
        period_vecprovinc_id: "20101",
        period_vecprovince_enable: "1"
      });
      this.assess_sedirectiors = result_period.data;
    },

    async period_sedirectorAssessBQuery() {
      let result_period;
      result_period = await this.$http.post("period_vecprovince.php", {
        ApiKey: this.ApiKey,
        period_vecprovinc_id: "20102",
        period_vecprovince_enable: "1"
      });
      this.assess_sedirectiors_B = result_period.data;
    },

    async period_vecprovinceTeacherQuery() {
      let result_period;
      result_period = await this.$http.post("period_vecprovince.php", {
        ApiKey: this.ApiKey,
        period_vecprovinc_id: "301",
        period_vecprovince_enable: "1"
      });
      this.period_vecprovinceTeacher = result_period.data;
    },

    async period_vecprovincemanageQuery() {
      let result_period;
      result_period = await this.$http.post("period_vecprovince.php", {
        ApiKey: this.ApiKey,
        period_vecprovinc_id: "101",
        period_vecprovince_enable: "1"
      });
      this.period_vecprovincemanage = result_period.data;
    },

    async getuser() {
      let result;
      let userSession = JSON.parse(sessionStorage.getItem("user")) || 0;
      result = await this.$http.post("admin.php", {
        user_name: userSession.user_name,
        provice: "OK",
        ApiKey: "HRvec2021"
      });
      this.user = result.data;
    },

    async getAlldata() {
      let result = await this.$http.post("show_dashboard_all.php", {
        province_ID: this.user.user_province_ID
      });
      this.showAlldata = result.data;

      console.log(this.user.province_ID);
    }
  }
};
</script>
